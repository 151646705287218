import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import AboutLanding from '../components/AboutLanding'
import portrait from '../assets/images/fujistyle.png'

const About = props => (
  <Layout>
    <Helmet>
      <title>Sebrene - About</title>
      <meta name="description" content="About" />
    </Helmet>

    <AboutLanding />
    <div id="main" className="alt">
      <section id="about">
        <div className="inner">
          <div className="grid-wrapper">
            <div className="col-2"></div>
            <div className="col-8">
              <div className="image">
                <img src={portrait} alt="look at the jpeg on this guy" />
              </div>
              <p>
                Who the fuck writes bios about themselves yeeeeesh this shit
                hard. Uhh I'm from Atlanta and I love music, like everyone else
                in this world does. I haven't been producing for very long but
                wow, it's a lot of fun. Every day I'm just trying to work on
                myself, and this music is I guess a representation of that, who
                knows. That about sums it all up. If you want to work with me or
                talk to me about anything just hit me up through email, dms,
                wherever. I'll listen.
              </p>
            <span className="icon alt fa-envelope"></span>&nbsp;
            <a href="mailto:contact@sebrene.com">contact@sebrene.com</a>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
)

export default About
